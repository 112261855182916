import React, { Component } from "react";
import ScrollReveal from "scrollreveal";

class Features extends Component {
	componentDidMount() {
		ScrollReveal({ reset: true }).reveal(".feature", {
			duration: 600,
			distance: "40px",
			easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
			interval: 100,
			origin: "bottom",
			viewFactor: 0.2
		});
	}

	render() {
		return (
			<section className="features section">
				<div className="container">
					<div className="features-inner section-inner has-bottom-divider">
						<div id="about" className="features-header text-center">
							<div className="container-sm">
								<h2 className="section-title mt-0">
									Hi, I&apos;m Gedas!
								</h2>
								<p className="section-paragraph">
									I&apos;m a Software Engineer with over 3
									years of experience. I have mainly worked on
									building Web applications as a Full-Stack
									Developer, but I also have in-depth
									knowledge of Computer Science concepts.
									Also, keen on AI and Machine Learning, in
									particular, Deep Learning.
								</p>
								<strong>
									Humble, positive and always hungry!
								</strong>
								<div className="features-image">
									<img
										className="features-illustration asset-dark"
										src={require("../images/features-illustration-dark.svg")}
										alt="Feature illustration"
									/>
									<img
										className="features-illustration asset-dark"
										src={require("../images/features-illustration-top-dark.svg")}
										alt="Feature illustration top"
									/>
									<img
										className="features-illustration asset-light"
										src={require("../images/features-illustration-light.svg")}
										alt="Feature illustration"
									/>
									<img
										className="features-illustration asset-light"
										src={require("../images/features-illustration-top-light.svg")}
										alt="Feature illustration top"
									/>
								</div>
							</div>
						</div>
						<div id="work" className="features-wrap">
							<div className="feature is-revealing">
								<div className="feature-inner">
									<div className="feature-icon">
										<img
											className="asset-light"
											src={require("../images/feature-01-light.svg")}
											alt="Software Engineer Feature Light Icon"
										/>
										<img
											className="asset-dark"
											src={require("../images/feature-01-dark.svg")}
											alt="Software Engineer Feature Dark Icon"
										/>
									</div>
									<h4 className="feature-title">
										Software Engineer
									</h4>
									<p className="text-sm">
										Building and delivering high-quality
										applications with performance,
										scalability and accessibility in mind.
									</p>
								</div>
							</div>
							<div className="feature is-revealing">
								<div className="feature-inner">
									<div className="feature-icon">
										<img
											className="asset-light"
											src={require("../images/feature-02-light.svg")}
											alt="AI Enthusiast Feature Light Icon"
										/>
										<img
											className="asset-dark"
											src={require("../images/feature-02-dark.svg")}
											alt="AI Enthusiast Feature Dark Icon"
										/>
									</div>
									<h4 className="feature-title">
										AI Enthusiast
									</h4>
									<p className="text-sm">
										Exploring and experimenting in the AI
										field and integrating Deep Learning
										models in Web Applications.
									</p>
								</div>
							</div>
							<div className="feature is-revealing">
								<div className="feature-inner">
									<div className="feature-icon">
										<img
											className="asset-light"
											src={require("../images/feature-03-light.svg")}
											alt="Contributor Feature Light Icon"
										/>
										<img
											className="asset-dark"
											src={require("../images/feature-03-dark.svg")}
											alt="Contributor Feature Dark Icon"
										/>
									</div>
									<h4 className="feature-title">
										Contributor
									</h4>
									<p className="text-sm">
										Contributing to the Apache Cordova open
										source cross-platform app development
										framework.
									</p>
								</div>
							</div>
							<div className="feature is-revealing">
								<div className="feature-inner">
									<div className="feature-icon">
										<img
											className="asset-light"
											src={require("../images/feature-04-light.svg")}
											alt="Mentor Feature Light Icon"
										/>
										<img
											className="asset-dark"
											src={require("../images/feature-04-dark.svg")}
											alt="Mentor Feature Dark Icon"
										/>
									</div>
									<h4 className="feature-title">Mentor</h4>
									<p className="text-sm">
										Teaching Udacity students programming
										with Python, Flask framework, PostgreSQL
										and SQLAlchemy.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Features;
