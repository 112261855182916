import Bubble from "./Bubble";

class BackgroundAnimation {
	constructor(canvas) {
		this.canvas = canvas;
		this.ctx = this.canvas.getContext("2d");
		this.dpr = window.devicePixelRatio;
		this.bubbleDensity = 111;
	}

	start() {
		this.canvasSize();
		window.addEventListener("resize", () => {
			this.canvasSize();
		});
		this.bubblesList = [];
		this.generateBubbles();
		this.animate();
	}

	canvasSize() {
		this.container = this.canvas.parentNode;
		// Determine window width and height
		this.w = this.container.offsetWidth;
		this.h = this.container.offsetHeight;
		this.wdpi = this.w * this.dpr;
		this.hdpi = this.h * this.dpr;
		// Set canvas width and height
		this.canvas.width = this.wdpi;
		this.canvas.height = this.hdpi;
		// Set width and height attributes
		this.canvas.style.width = `${this.w}px`;
		this.canvas.style.height = `${this.h}px`;
		// Scale down canvas
		this.ctx.scale(this.dpr, this.dpr);
	}

	animate() {
		this.ctx.clearRect(
			0,
			0,
			this.canvas.clientWidth,
			this.canvas.clientHeight
		);
		this.bubblesList.forEach(bubble => {
			bubble.update();
			this.ctx.translate(bubble.translateX, bubble.translateY);
			this.ctx.beginPath();
			this.ctx.arc(bubble.posX, bubble.posY, bubble.size, 0, 2 * Math.PI);
			this.ctx.fillStyle = `rgba(${bubble.color},${bubble.alpha})`;
			this.ctx.fill();
			this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0);
		});
		/* global requestAnimationFrame */
		requestAnimationFrame(this.animate.bind(this));
	}

	addBubble(bubble) {
		return this.bubblesList.push(bubble);
	}

	generateBubbles() {
		for (let i = 0; i < this.bubbleDensity; i++) {
			this.addBubble(new Bubble(this.canvas.parentNode));
		}
	}
}

export default BackgroundAnimation;
