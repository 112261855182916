import React, { Component } from "react";
import { Link } from "react-router-dom";

class Privacy extends Component {
	render() {
		return (
			<section className="privacy section">
				<article className="container">
					<header className="terms-header mb-48">
						<h1>Privacy Policy</h1>
						<p>Latest update: August 13, 2019</p>
					</header>

					<p>
						Gedas Gardauskas ("us", "we", or "our") operates the
						https://www.gedasga.com website (hereinafter referred to
						as the "Service").
					</p>
					<p>
						This page informs you of our policies regarding the
						collection, use, and disclosure of personal data when
						you use our Service and the choices you have associated
						with that data.
					</p>
					<p>
						We use your data to provide and improve the Service. By
						using the Service, you agree to the collection and use
						of information in accordance with this policy. Unless
						otherwise defined in this Privacy Policy, the terms used
						in this Privacy Policy have the same meanings as in our{" "}
						<Link to="/terms">Terms and Conditions</Link>.
					</p>

					<div className="privacy-content">
						<h2 className="mt-48 mb-32">Definitions</h2>
						<ul>
							<li>
								<p>
									<strong>Service</strong>
								</p>
								<p>
									Service is the https://www.gedasga.com
									website operated by Gedas Gardauskas.
								</p>
							</li>
							<li>
								<p>
									<strong>Personal Data</strong>
								</p>
								<p>
									Personal Data means data about a living
									individual who can be identified from those
									data (or from those and other information
									either in our possession or likely to come
									into our possession).
								</p>
							</li>
							<li>
								<p>
									<strong>Usage Data</strong>
								</p>
								<p>
									Usage Data is data collected automatically
									either generated by the use of the Service
									or from the Service infrastructure itself
									(for example, the duration of a page visit).
								</p>
							</li>
							<li>
								<p>
									<strong>Cookies</strong>
								</p>
								<p>
									Cookies are small files stored on your
									device (computer or mobile device).
								</p>
							</li>
						</ul>

						<h2 className="mt-48 mb-32">
							Information Collection and Use
						</h2>
						<p>
							We collect several different types of information
							for various purposes to provide and improve our
							Service to you.
						</p>

						<h3>Types of Data Collected</h3>

						<h4>Personal Data</h4>
						<p>
							While using our Service, we may ask you to provide
							us with certain personally identifiable information
							that can be used to contact or identify you
							("Personal Data"). Personally identifiable
							information may include, but is not limited to:
						</p>

						<ul>
							<li>Email address</li>
							<li>First name and last name</li>
							<li>Cookies and Usage Data</li>
						</ul>

						<h4>Usage Data</h4>
						<p>
							We may also collect information how the Service is
							accessed and used ("Usage Data"). This Usage Data
							may include information such as your computer's
							Internet Protocol address (e.g. IP address), browser
							type, browser version, the pages of our Service that
							you visit, the time and date of your visit, the time
							spent on those pages, unique device identifiers and
							other diagnostic data.
						</p>

						<h4>Tracking &amp; Cookies Data</h4>
						<p>
							We use cookies and similar tracking technologies to
							track the activity on our Service and we hold
							certain information.
						</p>
						<p>
							Cookies are files with a small amount of data which
							may include an anonymous unique identifier. Cookies
							are sent to your browser from a website and stored
							on your device. Other tracking technologies are also
							used such as beacons, tags and scripts to collect
							and track information and to improve and analyse our
							Service.
						</p>
						<p>
							You can instruct your browser to refuse all cookies
							or to indicate when a cookie is being sent. However,
							if you do not accept cookies, you may not be able to
							use some portions of our Service.
						</p>
						<p>Examples of Cookies we use:</p>
						<ul>
							<li>
								<strong>Session Cookies.</strong> We use Session
								Cookies to operate our Service.
							</li>
							<li>
								<strong>Preference Cookies.</strong> We use
								Preference Cookies to remember your preferences
								and various settings.
							</li>
							<li>
								<strong>Security Cookies.</strong> We use
								Security Cookies for security purposes.
							</li>
						</ul>

						<h3>What are your choices regarding cookies</h3>
						<p>
							If you'd like to delete cookies or instruct your web
							browser to delete or refuse cookies, please visit
							the help pages of your web browser. As an European
							citizen, under GDPR, you have certain individual
							rights. You can learn more about these rights in the{" "}
							<a href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR">
								GDPR Guide
							</a>
							.
						</p>
						<p>
							Please note, however, that if you delete cookies or
							refuse to accept them, you might not be able to use
							all of the features we offer, you may not be able to
							store your preferences, and some of our pages might
							not display properly.
						</p>

						<ul>
							<li>
								<p>
									For the Chrome web browser, please visit
									this page from Google:{" "}
									<a
										href="https://support.google.com/accounts/answer/32050"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://support.google.com/accounts/answer/32050
									</a>
								</p>
							</li>
							<li>
								<p>
									For the Internet Explorer web browser,
									please visit this page from Microsoft:{" "}
									<a
										href="http://support.microsoft.com/kb/278835"
										target="_blank"
										rel="noopener noreferrer"
									>
										http://support.microsoft.com/kb/278835
									</a>
								</p>
							</li>
							<li>
								<p>
									For the Firefox web browser, please visit
									this page from Mozilla:{" "}
									<a
										href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
									</a>
								</p>
							</li>
							<li>
								<p>
									For the Safari web browser, please visit
									this page from Apple:{" "}
									<a
										href="https://support.apple.com/kb/PH21411?locale=en_US"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://support.apple.com/kb/PH21411?locale=en_US
									</a>
								</p>
							</li>
							<li>
								<p>
									For any other web browser, please visit your
									web browser's official web pages.
								</p>
							</li>
						</ul>

						<h3>
							Where can you find more information about cookies
						</h3>
						<p>
							You can learn more about cookies and the following
							third-party websites:
						</p>
						<ul>
							<li>
								<a
									href="http://www.allaboutcookies.org/"
									target="_blank"
									rel="noopener noreferrer"
								>
									AllAboutCookies
								</a>
							</li>
							<li>
								<a href="http://www.networkadvertising.org/">
									Network Advertising Initiative
								</a>
							</li>
						</ul>

						<h2 className="mt-48 mb-32">Use of Data</h2>
						<p>
							Gedas Gardauskas uses the collected data for various
							purposes:
						</p>
						<ul>
							<li>To provide and maintain the Service</li>
							<li>To notify you about changes to our Service</li>
							<li>
								To allow you to participate in interactive
								features of our Service when you choose to do so
							</li>
							<li>To provide customer care and support</li>
							<li>
								To provide analysis or valuable information so
								that we can improve the Service
							</li>
							<li>To monitor the usage of the Service</li>
							<li>
								To detect, prevent and address technical issues
							</li>
						</ul>

						<h2 className="mt-48 mb-32">Disclosure Of Data</h2>

						<h3>Legal Requirements</h3>
						<p>
							Gedas Gardauskas may disclose your Personal Data in
							the good faith belief that such action is necessary
							to:
						</p>
						<ul>
							<li>To comply with a legal obligation</li>
							<li>
								To protect and defend the rights or property of
								Gedas Gardauskas
							</li>
							<li>
								To prevent or investigate possible wrongdoing in
								connection with the Service
							</li>
							<li>
								To protect the personal safety of users of the
								Service or the public
							</li>
							<li>To protect against legal liability</li>
						</ul>

						<p>
							As an European citizen, under GDPR, you have certain
							individual rights. You can learn more about these
							guides in the{" "}
							<a
								href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR"
								target="_blank"
								rel="noopener noreferrer"
							>
								GDPR Guide
							</a>
							.
						</p>

						<h2 className="mt-48 mb-32">Security of Data</h2>
						<p>
							The security of your data is important to us but
							remember that no method of transmission over the
							Internet or method of electronic storage is 100%
							secure. While we strive to use commercially
							acceptable means to protect your Personal Data, we
							cannot guarantee its absolute security.
						</p>

						<h2 className="mt-48 mb-32">Service Providers</h2>

						<p>
							We may employ third party companies and individuals
							to facilitate our Service ("Service Providers"), to
							provide the Service on our behalf, to perform
							Service-related services or to assist us in
							analyzing how our Service is used.
						</p>
						<p>
							These third parties have access to your Personal
							Data only to perform these tasks on our behalf and
							are obligated not to disclose or use it for any
							other purpose.
						</p>

						<h3>Analytics</h3>
						<p>
							We may use third-party Service Providers to monitor
							and analyze the use of our Service.
						</p>
						<ul>
							<li>
								<p>
									<strong>Google Analytics</strong>
								</p>
								<p>
									Google Analytics is a web analytics service
									offered by Google that tracks and reports
									website traffic. Google uses the data
									collected to track and monitor the use of
									our Service. This data is shared with other
									Google services. Google may use the
									collected data to contextualize and
									personalize the ads of its own advertising
									network.
									<br />
									<br />
									Personal Data collected:
									<ul>
										<li>Cookies</li>
										<li>Usage Data</li>
									</ul>
									Place of processing:
									<ul>
										<li>United States</li>
									</ul>
									<a
										href="http://www.google.com/intl/en/policies/privacy/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>{" "}
									|{" "}
									<a
										href="https://tools.google.com/dlpage/gaoptout?hl=en"
										target="_blank"
										rel="noopener noreferrer"
									>
										Opt Out
									</a>
								</p>
							</li>
						</ul>

						<h3>Managing contacts and sending messages</h3>
						<p>
							We may use third-party Service Providers to to
							manage a database of email contact, phone contacts
							or any other contact information. These services may
							also collect data concerning the date and time when
							the message was viewed.
						</p>
						<ul>
							<li>
								<p>
									<strong>HubSpot</strong>
								</p>
								<p>
									HubSpot is a developer and marketer of
									software products for inbound marketing and
									sales. We use HubSpot Live Chat Software. It
									provides an ability to connect with website
									visitors in real time and provide a better
									customers experience. Every conversation
									gets automatically saved and stored in
									conversations inbox on HubSpot.
									<br />
									<br />
									Personal Data collected:
									<ul>
										<li>Cookies</li>
										<li>Personal Data</li>
									</ul>
									Place of processing:
									<ul>
										<li>United States</li>
									</ul>
									<a
										href="https://legal.hubspot.com/privacy-policy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>
								</p>
							</li>
						</ul>

						<h2 className="mt-48 mb-32">Links to Other Sites</h2>
						<p>
							Our Service may contain links to other sites that
							are not operated by us. If you click a third party
							link, you will be directed to that third party's
							site. We strongly advise you to review the Privacy
							Policy of every site you visit.
						</p>
						<p>
							We have no control over and assume no responsibility
							for the content, privacy policies or practices of
							any third party sites or services.
						</p>

						<h2 className="mt-48 mb-32">Children's Privacy</h2>
						<p>
							Our Service does not address anyone under the age of
							18 ("Children").
						</p>
						<p>
							We do not knowingly collect personally identifiable
							information from anyone under the age of 18. If you
							are a parent or guardian and you are aware that your
							Child has provided us with Personal Data, please
							contact us. If we become aware that we have
							collected Personal Data from children without
							verification of parental consent, we take steps to
							remove that information from our servers.
						</p>

						<h2 className="mt-48 mb-32">
							Changes to This Privacy Policy
						</h2>
						<p>
							We may update our Privacy Policy from time to time.
							We will notify you of any changes by posting the new
							Privacy Policy on this page.
						</p>
						<p>
							We will let you know via email and/or a prominent
							notice on our Service, prior to the change becoming
							effective and update the "effective date" at the top
							of this Privacy Policy.
						</p>
						<p>
							You are advised to review this Privacy Policy
							periodically for any changes. Changes to this
							Privacy Policy are effective when they are posted on
							this page.
						</p>

						<h2 className="mt-48 mb-32">Contact</h2>
						<p>
							All questions, comments and requests regarding this
							Privacy Policy should be addressed to{" "}
							<a href="mailto:gdpr@devbridge.com">
								gedas.gardauskas@gmail.com
							</a>
							.
						</p>
					</div>
				</article>
			</section>
		);
	}
}

export default Privacy;
