import React, { Component } from "react";

class Terms extends Component {
	render() {
		return (
			<section className="terms section">
				<article className="container">
					<header className="terms-header mb-48">
						<h1>Terms and Conditions</h1>
						<p>Latest update: August 13, 2019</p>
					</header>
					<p>
						Gedas Gardauskas ("us", "we", or "our") operates the
						https://www.gedasga.com website (hereinafter referred to
						as the "Service").
					</p>

					<p>
						Please read these Terms and Conditions ("Terms", "Terms
						of Service") carefully before using the Service.
					</p>
					<p>
						Your access to and use of the Service is conditioned on
						your acceptance of and compliance with these Terms.
						These Terms apply to all visitors, users and others who
						access or use the Service.
					</p>
					<p>
						By accessing or using the Service you agree to be bound
						by these Terms. If you disagree with any part of the
						terms then you may not access the Service.
					</p>
					<div className="terms-content">
						<h2 className="mt-48 mb-32">
							Links To Other Web Sites
						</h2>
						<p>
							Our Service may contain links to third-party web
							sites or services that are not owned or controlled
							by Gedas Gardauskas.
						</p>
						<p>
							Gedas Gardauskas has no control over, and assumes no
							responsibility for, the content, privacy policies,
							or practices of any third party web sites or
							services. You further acknowledge and agree that
							Gedas Gardauskas shall not be responsible or liable,
							directly or indirectly, for any damage or loss
							caused or alleged to be caused by or in connection
							with use of or reliance on any such content, goods
							or services available on or through any such web
							sites or services.
						</p>
						<p>
							We strongly advise you to read the terms and
							conditions and privacy policies of any third-party
							web sites or services that you visit.
						</p>

						<h2 className="mt-48 mb-32">Governing Law</h2>
						<p>
							These Terms shall be governed and construed in
							accordance with the laws of Lithuania, without
							regard to its conflict of law provisions.
						</p>
						<p>
							Our failure to enforce any right or provision of
							these Terms will not be considered a waiver of those
							rights. If any provision of these Terms is held to
							be invalid or unenforceable by a court, the
							remaining provisions of these Terms will remain in
							effect. These Terms constitute the entire agreement
							between us regarding our Service, and supersede and
							replace any prior agreements we might have between
							us regarding the Service.
						</p>

						<h2 className="mt-48 mb-32">Changes</h2>
						<p>
							We reserve the right, at our sole discretion, to
							modify or replace these Terms at any time. If a
							revision is material we will try to provide at least
							30 days notice prior to any new terms taking effect.
							What constitutes a material change will be
							determined at our sole discretion.
						</p>
						<p>
							By continuing to access or use our Service after
							those revisions become effective, you agree to be
							bound by the revised terms. If you do not agree to
							the new terms, please stop using the Service.
						</p>

						<h2 className="mt-48 mb-32">Contact</h2>
						<p>
							All questions, comments and requests regarding these
							Terms and Conditions should be addressed to{" "}
							<a href="mailto:gdpr@devbridge.com">
								gedas.gardauskas@gmail.com
							</a>
							.
						</p>
					</div>
				</article>
			</section>
		);
	}
}

export default Terms;
