import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { changeThemeMode } from "../actions/theme";

// Pages
import HomePage from "../pages/HomePage";
import PrivacyPage from "../pages/PrivacyPage";
import TermsPage from "../pages/TermsPage";
import SitemapPage from "../pages/SitemapPage";
import NotFoundPage from "../pages/NotFoundPage";

require("../scss/style.scss");

class App extends React.Component {
	render() {
		return (
			<Switch>
				<Route path="/" exact component={HomePage} />
				<Route path="/privacy" exact component={PrivacyPage} />
				<Route path="/terms" exact component={TermsPage} />
				<Route path="/sitemap" exact component={SitemapPage} />
				<Route component={NotFoundPage} />
			</Switch>
		);
	}
}

const mapStateToProps = state => {
	return {
		darkMode: state.theme.darkMode
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onThemeButtonChange: () => dispatch(changeThemeMode())
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(App)
);
