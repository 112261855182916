import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
	render() {
		return (
			<section className="section">
				<div className="container text-center mb-32">
					<h1>404</h1>
					<h3>Page Not Found</h3>
					<div className="has-top-divider mt-32">
						<br />
						<p>
							The resource that you are attempting to access does
							not exist or you don't have the necessary
							permissions to view it.
						</p>
						<p>
							Make sure the address is correct and that the page
							hasn't moved.
						</p>
						<Link class="button button-primary" to="/">
							Return Home
						</Link>
					</div>
				</div>
			</section>
		);
	}
}

export default NotFound;
