import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
	getYear() {
		let dateOjb = new Date();
		return dateOjb.getFullYear();
	}

	render() {
		return (
			<footer className="site-footer has-top-divider">
				<div className="container">
					<div className="site-footer-inner">
						<div className="brand footer-brand">
							<Link to="/">
								<img
									className="asset-light"
									src={require("../images/logo-light.svg")}
									alt="Logo"
								/>
								<img
									className="asset-dark"
									src={require("../images/logo-dark.svg")}
									alt="Logo"
								/>
							</Link>
						</div>
						<ul className="footer-links list-reset">
							<li>
								<Link to="/privacy">Privacy</Link>
							</li>
							<li>
								<Link to="/terms">Terms</Link>
							</li>
							<li>
								<Link to="/sitemap">Sitemap</Link>
							</li>
						</ul>
						<ul className="footer-social-links list-reset">
							<li>
								<a
									href="https://www.facebook.com/gedasga"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">
										Facebook
									</span>
									<svg
										id="facebook"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 16 16"
										width="21"
										height="21"
									>
										<path d="M16,8.05a8,8,0,1,0-9.25,8V10.38h-2V8.05h2V6.28a2.83,2.83,0,0,1,3-3.14,11.92,11.92,0,0,1,1.79.16v2h-1a1.16,1.16,0,0,0-1.3,1.26V8.05h2.22l-.36,2.33H9.25V16A8,8,0,0,0,16,8.05Z" />
									</svg>
								</a>
							</li>
							<li>
								<a
									href="https://github.com/GedasGa"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">
										GitHub
									</span>
									<svg
										id="github"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 16 16"
										width="21"
										height="21"
									>
										<path d="M8,0A8.11,8.11,0,0,0,0,8.19,8.2,8.2,0,0,0,5.5,16c.4.07.55-.18.55-.4v-1.4c-2.25.5-2.72-1.1-2.72-1.1a2.19,2.19,0,0,0-.89-1.2c-.73-.51.06-.51.06-.51a1.71,1.71,0,0,1,1.22.85,1.7,1.7,0,0,0,2.33.69h0a1.77,1.77,0,0,1,.49-1.1c-1.78-.21-3.65-.91-3.65-4a3.18,3.18,0,0,1,.82-2.2A3.06,3.06,0,0,1,3.8,3.42S4.47,3.2,6,4.26a7.42,7.42,0,0,1,4,0c1.53-1.06,2.2-.84,2.2-.84a3,3,0,0,1,.1,2.15,3.18,3.18,0,0,1,.82,2.2c0,3.16-1.87,3.85-3.66,4A2,2,0,0,1,10,13.31v2.25c0,.27.15.48.56.4a8.26,8.26,0,0,0,5-10.43A8,8,0,0,0,8,0Z" />
									</svg>
								</a>
							</li>
							<li>
								<a
									href="https://gitlab.com/GedasGa"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">
										GitLab
									</span>
									<svg
										id="gitlab"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 17.37 16"
										height="21"
									>
										<path
											className="gitlab-dark-orange-shape"
											d="M8.68,16h0l3.2-9.85H5.49Z"
										/>
										<path
											className="gitlab-orange-shape"
											d="M8.68,16,5.48,6.15H1Z"
										/>
										<path
											className="gitlab-light-orange-shape"
											d="M1,6.16H1l-1,3a.66.66,0,0,0,.24.74L8.68,16Z"
										/>
										<path
											className="gitlab-dark-orange-shape"
											d="M1,6.16H5.49L3.56.23A.34.34,0,0,0,3.14,0a.32.32,0,0,0-.21.21Z"
										/>
										<path
											className="gitlab-orange-shape"
											d="M8.68,16l3.2-9.85h4.49Z"
										/>
										<path
											className="gitlab-light-orange-shape"
											d="M16.36,6.16h0l1,3a.68.68,0,0,1-.24.74L8.68,16l7.68-9.84Z"
										/>
										<path
											className="gitlab-dark-orange-shape"
											d="M16.36,6.16H11.88L13.81.23A.33.33,0,0,1,14.23,0a.32.32,0,0,1,.21.21l1.92,5.93Z"
										/>
									</svg>
								</a>
							</li>
							<li>
								<a
									href="https://www.linkedin.com/in/gedas-gardauskas"
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">
										LinkedIn
									</span>
									<svg
										id="linkedin"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 16 16"
										width="21"
										height="21"
									>
										<path d="M14.1,0H1.9A1.9,1.9,0,0,0,0,1.9H0V14.1A1.9,1.9,0,0,0,1.9,16H14.1A1.9,1.9,0,0,0,16,14.1V1.9A1.9,1.9,0,0,0,14.1,0ZM5,6.1v7.23H2.67V6.1ZM2.67,4a1,1,0,0,1,1.05-1h.09A1,1,0,0,1,5,3.85.77.77,0,0,1,5,4,1,1,0,0,1,4,5L3.81,5a1,1,0,0,1-1.13-.85A.76.76,0,0,1,2.67,4Zm10.66,9.34H11.05V9.52A1.37,1.37,0,0,0,9.84,8H9.71A1.36,1.36,0,0,0,8.4,9.41a.57.57,0,0,0,0,.13v3.81H6.1V6.1H8.38v1a2.87,2.87,0,0,1,2.21-1c1.52,0,2.74,1,2.74,3.14Z" />
									</svg>
								</a>
							</li>
						</ul>
						<div className="footer-copyright">
							&copy; {this.getYear()} Gedas Gardauskas
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
