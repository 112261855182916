class Bubble {
	constructor(parentNode) {
		this.parentNode = parentNode;
		this.getCanvasSize();
		window.addEventListener("resize", () => {
			this.getCanvasSize();
		});
		this.mouseX = 0;
		this.mouseY = 0;
		window.addEventListener("mousemove", e => {
			this.mouseX = e.clientX;
			this.mouseY = e.clientY;
		});
		this.randomise();
	}

	getCanvasSize() {
		this.canvasWidth = this.parentNode.clientWidth;
		this.canvasHeight = this.parentNode.clientHeight;
	}

	generateDecimalBetween(min, max) {
		return (Math.random() * (min - max) + max).toFixed(2);
	}

	update() {
		this.translateX -= this.movementX;
		this.translateY -= this.movementY;
		this.posX +=
			(this.mouseX / (this.staticity / this.magnetism) - this.posX) /
			this.smoothFactor;
		this.posY +=
			(this.mouseY / (this.staticity / this.magnetism) - this.posY) /
			this.smoothFactor;

		if (
			this.translateY + this.posY < 0 ||
			this.translateX + this.posX < 0 ||
			this.translateX + this.posX > this.canvasWidth
		) {
			this.randomise();
			this.translateY = this.canvasHeight;
		}
	}

	randomise() {
		this.colors = [
			"85,107,139",
			"38,141,247",
			"66,52,248",
			"255,108,80",
			"243, 244, 255",
			"96, 100, 131"
		];
		this.velocity = 30; // Bubble levitation velocity (the higher the slower)
		this.smoothFactor = 50; // The higher, the smoother
		this.staticity = 30; // Increase value to make bubbles move slower on mousemove
		this.magnetism = 0.1 + Math.random() * 4;
		this.color = this.colors[
			Math.floor(Math.random() * this.colors.length)
		];
		this.alpha = this.generateDecimalBetween(5, 10) / 10;
		this.size = this.generateDecimalBetween(1, 4);
		this.posX = 0;
		this.posY = 0;
		this.movementX = this.generateDecimalBetween(-2, 2) / this.velocity;
		this.movementY = this.generateDecimalBetween(1, 20) / this.velocity;
		this.translateX = this.generateDecimalBetween(0, this.canvasWidth);
		this.translateY = this.generateDecimalBetween(0, this.canvasHeight);
	}
}

export default Bubble;
