import React from "react";

// Components
import HeaderWithThemeButton from "../components/HeaderWithThemeButton";
import NotFound from "../components/NotFound";
import Footer from "../components/Footer";

const NotFoundPage = () => {
	return (
		<main>
			<HeaderWithThemeButton />
			<NotFound />
			<Footer />
		</main>
	);
};

export default NotFoundPage;
