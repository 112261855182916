import React, { Component } from "react";
import ScrollReveal from "scrollreveal";
import ContactButton from "./ContactButton";

class Cta extends Component {
	componentDidMount() {
		ScrollReveal({ reset: true }).reveal(".cta", {
			duration: 600,
			distance: "40px",
			easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
			interval: 100,
			origin: "bottom",
			viewFactor: 0.2
		});
	}

	render() {
		return (
			<section id="contact" className="cta section">
				<div className="container-sm">
					<div className="cta-inner section-inner">
						<div className="cta-header text-center">
							<h2 className="section-title mt-0">
								{this.props.title}
							</h2>
							<p className="section-paragraph">
								{this.props.text}
							</p>
							<div className="cta-cta">
								<ContactButton message={this.props.message} />
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Cta;
