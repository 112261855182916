import { combineReducers } from "redux";

// Reducers
import theme from "./theme";

// Combine Reducers
const reducers = combineReducers({
  theme
});

export default reducers;
