import React from "react";

// Components
import HeaderWithThemeButton from "../components/HeaderWithThemeButton";
import Sitemap from "../components/Sitemap";
import Footer from "../components/Footer";

const SitemapPage = () => {
	return (
		<main>
			<HeaderWithThemeButton />
			<Sitemap />
			<Footer />
		</main>
	);
};

export default SitemapPage;
