import React from "react";

// Components
import HeaderWithThemeButton from "../components/HeaderWithThemeButton";
import Privacy from "../components/Privacy";
import Footer from "../components/Footer";

const PrivacyPage = () => {
	return (
		<main>
			<HeaderWithThemeButton />
			<Privacy />
			<Footer />
		</main>
	);
};

export default PrivacyPage;
