import React from "react";

// Components
import Header from "../components/Header";
import Features from "../components/Features";
import Cta from "../components/Cta";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
// import Terminal from "../components/Terminal";

const HomePage = () => {
	return (
		<main>
			<Header />
			<Hero />
			<Features />
			{/* <Terminal /> */}
			<Cta
				title="Interested in working together?"
				text="I’m always open to discuss a collaboration or partnership opportunities."
				message={"Coffee's on me!"}
			/>
			<Footer />
		</main>
	);
};

export default HomePage;
