import React from "react";

// Components
import HeaderWithThemeButton from "../components/HeaderWithThemeButton";
import Terms from "../components/Terms";
import Footer from "../components/Footer";

const TermsPage = () => {
	return (
		<main>
			<HeaderWithThemeButton />
			<Terms />
			<Footer />
		</main>
	);
};

export default TermsPage;
