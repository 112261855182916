import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import router from "./router";

ReactDOM.render(
  <Provider store={store}>{router}</Provider>,
  document.getElementById("root")
);

serviceWorker.register();

(function() {
  const { body } = document;

  document.documentElement.classList.remove("no-js");
  document.documentElement.classList.add("js");

  window.addEventListener("load", function() {
    body.classList.add("is-loaded");
  });

  window.requestAnimFrame = (function() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();
})();
