import React from "react";

const ContactButton = ({ message }) => {
	return (
		<a
			className="button button-primary"
			href="mailto:gedas.gardauskas@gmail.com"
		>
			{message}
		</a>
	);
};

export default ContactButton;
