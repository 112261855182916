import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
	render() {
		return (
			<header className="site-header">
				<div className="container">
					<div className="site-header-inner">
						<div className="brand header-brand">
							<h1 className="m-0">
								<Link to="/">
									<img
										className="header-logo-image asset-light"
										src={require("../images/logo-light.svg")}
										alt="Logo"
									/>
									<img
										className="header-logo-image asset-dark"
										src={require("../images/logo-dark.svg")}
										alt="Logo"
									/>
								</Link>
							</h1>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
