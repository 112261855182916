import React, { Component } from "react";
import { Link } from "react-router-dom";

class Sitemap extends Component {
	render() {
		return (
			<section className="sitemap section">
				<article className="container">
					<header className="sitemap-header mb-48">
						<h1>Sitemap</h1>
					</header>
					<div className="sitemap-content">
						<ul style={{ "list-style": "none" }}>
							<li className="mb-16 has-bottom-divider">
								<Link to="/">
									Software Engineer, Web Developer, AI
									enthusiast & Mentor
								</Link>
							</li>
							<li className="mb-16 has-bottom-divider">
								<Link to="/terms">Terms and Conditions</Link>
							</li>
							<li className="mb-16 has-bottom-divider">
								<Link to="/privacy">Privacy Policy</Link>
							</li>
						</ul>
					</div>
				</article>
			</section>
		);
	}
}

export default Sitemap;
