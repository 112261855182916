import React, { Component } from "react";
import ReactDOM from "react-dom";
import ContactButton from "./ContactButton";
import ThemeButton from "./ThemeButton";
import BackgroundAnimation from "./BackgroundAnimation";

class Hero extends Component {
	componentDidMount() {
		const canvas = ReactDOM.findDOMNode(this.refs["hero-particles"]);
		const heroParticles = new BackgroundAnimation(canvas);
		heroParticles.start();
	}

	render() {
		return (
			<section className="hero">
				<div className="hero-particles-container">
					<canvas ref="hero-particles" />
				</div>
				<div className="container">
					<div className="hero-inner">
						<div className="hero-copy">
							<h1 className="hero-title mt-0">
								Software Engineer, Web Developer, AI enthusiast & Mentor
							</h1>
							<p className="hero-paragraph">
								Design and deliver beautifully simple, scalable
								and reliable applications accessible to
								everyone.
							</p>
							<div className="hero-cta">
								<ContactButton message="Get in touch!" />
								<ThemeButton />
							</div>
						</div>
						<div className="hero-media">
							<div className="header-illustration">
								<img
									className="header-illustration-image asset-light"
									src={require("../images/header-illustration-light.svg")}
									alt="Header illustration"
								/>
								<img
									className="header-illustration-image asset-dark"
									src={require("../images/header-illustration-dark.svg")}
									alt="Header illustration"
								/>
							</div>
							<div className="hero-media-illustration">
								<img
									className="hero-media-illustration-image asset-light"
									src={require("../images/hero-media-illustration-light.svg")}
									alt="Hero media illustration"
								/>
								<img
									className="hero-media-illustration-image asset-dark"
									src={require("../images/hero-media-illustration-dark.svg")}
									alt="Hero media illustration"
								/>
							</div>
							<div className="hero-media-container">
								<a
									href={require("../misc/vCard.vcf")}
									download="GedasGa-vCard.vcf"
								>
									<img
										className="hero-media-image asset-light"
										src={require("../images/hero-media-light.svg")}
										alt="Gedas Gardauskas QR-Code vCard"
									/>
									<img
										className="hero-media-image asset-dark"
										src={require("../images/hero-media-dark.svg")}
										alt="Gedas Gardauskas QR-Code vCard"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Hero;
