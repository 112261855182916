import { CHANGE_THEME_MODE } from "../actions/theme";

const initialThemeState = {
  darkMode: true
};

const theme = (state = initialThemeState, action = {}) => {
  switch (action.type) {
    case CHANGE_THEME_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      }
    default:
      return state;
  }
};

export default theme;
