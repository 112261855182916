import * as React from "react";
import store from "../store.js";
import { changeThemeMode } from "../actions/theme";

class ThemeButton extends React.Component {
	changeTheme = () => {
		const { body } = document;
		store.dispatch(changeThemeMode());
		if (store.getState().theme.darkMode) {
			body.classList.add("lights-off");
		} else {
			body.classList.remove("lights-off");
		}
	};

	render() {
		return (
			<div className="lights-toggle">
				<input
					id="lights-toggle"
					type="checkbox"
					name="lights-toggle"
					className="switch"
					onClick={this.changeTheme}
					checked={!store.getState().theme.darkMode}
				/>
				<label htmlFor="lights-toggle" className="text-xs">
					<span>
						Turn me
						<span className="label-text">
							{store.getState().theme.darkMode ? " light" : " dark"}
						</span>
					</span>
				</label>
			</div>
		);
	}
}

export default ThemeButton;
